<template>
  <v-card id="card-modal-assign-driver" class="elevation-4">
    <v-card-title class="justify-start py-2 card-modaltitle">
      <div class="pl-4">
        <h3 class="primary--text titleOffersTripModal">
          {{ $t("components.views.trip.no_offer_assign.modal_offer_trips.offer_trip") }}
        </h3>
      </div>
      <v-btn depressed text color="primary" class="cont" @click.stop="$emit('closeForm')">
        <v-icon class="mr-1">fa-solid fa-xmark</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="px-0 py-2">
      <v-container fluid class="pa-0 mt-0">
        <v-stepper v-model="step" class="elevation-0">
          <v-row>
            <v-col cols="12" class="pt-0 mt-0">
              <v-stepper-header class="elevation-0">
                <v-stepper-step :complete="step > 1" step="1"  >
                  {{ $t("components.views.trip.no_offer_assign.modal_offer_trips.type_of_offer") }}
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="step > 2" step="2" >
                  {{ nameSelect }}
                </v-stepper-step>
                <v-divider v-if="advance"></v-divider>
                <v-stepper-step :complete="step > 3" step="3" v-if="advance" >
                  {{ $t("components.views.trip.no_offer_assign.modal_offer_trips.advanced") }}
                </v-stepper-step>
              </v-stepper-header>
            </v-col>
          </v-row>
          <v-stepper-items >
            <v-row>
              <v-col cols="12">
                <v-stepper-content step="1" class="pa-0">
                  <v-container>
                    <v-row class="justify-start">
                      <v-col cols="12">
                        <v-radio-group v-model="stepType" class="pl-6">
                          <v-radio
                            :label="
                              $t(
                                'components.views.trip.no_offer_assign.modal_offer_trips.company_transporter_name'
                              )
                            "
                            value="1"
                            class="mb-4"
                          />
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row class="justify-end px-2 py-2 " >
                      <v-col cols="3" class="py-0 pr-3 pb-1">
                        <v-btn color="primary" @click="step = 2" block depressed>
                          <v-icon small class="mr-1">fa-solid fa-arrow-right</v-icon>
                          {{ $t("components.views.trip.no_offer_assign.modal_offer_trips.next") }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-stepper-content>

                <v-stepper-content step="2" class="pa-0">
                  <v-container class="pa-0">
                    <v-form v-model="validSimple"  >
                      <v-row class="px-5">
                        <v-col cols="12">
                          <SelectAutocomplete
                            v-model="companys"
                            return-object
                            :data="dataBusiness"
                            item-text="businessName"
                            item-value="idCompany"
                            dense
                            :label="
                              $t(
                                'components.views.trip.no_offer_assign.modal_offer_trips.company_transporter_name'
                              )
                            "
                            :placeholder="
                              $t(
                                'components.views.trip.no_offer_assign.modal_offer_trips.search_company_transporter_name'
                              )
                            "
                            :rules="[
                              required(
                                $t(
                                  'components.views.trip.no_offer_assign.modal_offer_trips.company_transporter_name'
                                ),
                                1
                              ),
                            ]"
                            required
                            :selected="companys"
                            :init="false"
                            chips
                            multiple
                            v-bind="$attrs"
                            v-on="$listeners"
                          >
                            <template v-slot:prepend-item>
                              <v-list-item ripple @mousedown.prevent @click="toggleSimple">
                                <v-list-item-action>
                                  <v-icon :class="icon"  />
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{
                                      $t(
                                        "components.views.trip.no_offer_assign.modal_offer_trips.select_all"
                                      )
                                    }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider class="mt-2"></v-divider>
                            </template>
                            <template v-slot:selection="{ attrs, item, parent, selected }">
                              <v-chip v-bind="attrs" :input-value="selected" label small>
                                <span class="pr-2">
                                  {{ item.businessName }}
                                </span>
                                <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                              </v-chip>
                            </template>
                          </SelectAutocomplete>
                        </v-col>
                      </v-row>
                      <v-row class="personalize card-modalbtn px-7 pt-1">
                        <v-col cols="12">
                          <v-row class="justify-start" >
                            <v-btn color="secondary" @click="step = 1" depressed>
                              <v-icon small class="mr-1">fa-solid fa-arrow-left</v-icon>
                              {{
                                $t(
                                  "components.views.trip.no_offer_assign.modal_offer_trips.previous"
                                )
                              }}
                            </v-btn>
                            <v-btn
                              color="primary"
                              class="ml-4"
                              depressed
                              :disabled="!validSimple"
                              @click="offerSimple()"
                            >
                              <v-icon small class="mr-1">fa-solid fa-check</v-icon>
                              {{
                                $t("components.views.trip.no_offer_assign.modal_offer_trips.offer")
                              }}
                            </v-btn>
                            <v-btn
                              color="grey lighten-1 white--text"
                              class="ml-4"
                              depressed
                              @click.stop="$emit('closeForm')"
                            >
                              <v-icon small size="15" class="mr-1"> fa-solid fa-xmark </v-icon>
                              {{
                                $t("components.views.trip.no_offer_assign.modal_offer_trips.cancel")
                              }}
                            </v-btn>
                            <v-col cols="auto" class="pt-0 cont">
                              <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                  <v-btn
                                    color="primary  white--text"
                                    @click="onShowCogAdvance()"
                                    depressed
                                    v-bind="attrs"
                                    :disabled="companys.length > 0"
                                    v-on="on"
                                  >
                                    <v-icon small class="mr-1">fa-solid fa-gear</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{
                                  $t(
                                    "components.views.trip.no_offer_assign.modal_offer_trips.advanced"
                                  )
                                }}</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-stepper-content>
                <v-stepper-content step="3" class="pa-0">
                  <v-container>
                    <v-form  v-model="validAdvance">
                      <v-row class="pr-3 pl-5">
                        <v-col cols="12">
                          <div class="subtitle pb-1">
                            <v-row>
                              <v-col cols="9" md="auto" justify-center class="mt-0">
                                <h3 class="secondary--text">
                                  {{
                                    $t(
                                      "components.views.trip.no_offer_assign.modal_offer_trips.intervals"
                                    )
                                  }}
                                </h3>
                              </v-col>
                              <v-spacer />
                              <v-col cols="2" md="1">
                                <v-tooltip bottom>
                                  <template #activator="{ on, attrs }">
                                    <v-btn
                                      color="primary"
                                      justify-center
                                      depressed
                                      block
                                      style="height: 40px;"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="addFields('fieldsFroms')"
                                      :disabled="lengthFieldsFroms >= 5"
                                    >
                                      <v-icon size="16">fa-solid fa-plus</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>{{
                                    $t(
                                      "components.views.trip.no_offer_assign.modal_offer_trips.add_interval"
                                    )
                                  }}</span>
                                </v-tooltip>
                              </v-col>
                            </v-row>
                          </div>
                        </v-col>
                      </v-row>
                      <div v-for="(fieldFrom, a) in fieldsFroms" :key="'field' + a" class="pt-5 pl-5 pr-3" >
                        <v-row>
                          <v-col>
                            {{
                              $t("components.views.trip.no_offer_assign.modal_offer_trips.interval")
                            }}
                            N° {{ a + 1 }}
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4" class="pb-0">
                            <DateTimePicker
                              :label="
                                $t(
                                  'components.views.trip.no_offer_assign.modal_offer_trips.start_date_and_time'
                                )
                              "
                              v-model="fieldFrom.valueStartTimeDate"
                              :value="fieldFrom.valueStartTimeDate"
                              readonly
                              dense
                              :min="minInit"
                              :max="fieldFrom.valueEndTimeDate"
                              @input="publishTimeOn()"
                            />
                          </v-col>
                          <v-col cols="4" class="pb-0">
                            <DateTimePicker
                              :label="
                                $t(
                                  'components.views.trip.no_offer_assign.modal_offer_trips.end_date_and_time'
                                )
                              "
                              v-model="fieldFrom.valueEndTimeDate"
                              :value="fieldFrom.valueEndTimeDate"
                              readonly
                              dense
                              :disabled="fieldFrom.propDisabled"
                              :min="fieldFrom.valueStartTimeDate"
                              @input="publishTimeOn()"
                            />
                          </v-col>
                          <v-col cols="4" class="pb-0">
                            <v-text-field
                              v-model="fieldFrom.valueTimePublic"
                              outlined
                              readonly
                              dense
                              :rules="[
                                required(
                                  $t(
                                    'components.views.trip.no_offer_assign.modal_offer_trips.published_time'
                                  )
                                ),
                              ]"
                              required
                              :label="
                                $t(
                                  'components.views.trip.no_offer_assign.modal_offer_trips.published_time'
                                )
                              "
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="11" class="py-0" >
                            <SelectAutocomplete
                              v-model="fieldFrom.transportCompanies"
                              return-object
                              :data="dataBusiness"
                              :selected="fieldFrom.transportCompanies"
                              item-text="businessName"
                              item-value="idCompany"
                              dense
                              :rules="[
                                required(
                                  $t(
                                    'components.views.trip.no_offer_assign.modal_offer_trips.published_time'
                                  )
                                ),
                              ]"
                              :label="
                                $t(
                                  'components.views.trip.no_offer_assign.modal_offer_trips.company_transporter_name'
                                )
                              "
                              :placeholder="
                                $t(
                                  'components.views.trip.no_offer_assign.modal_offer_trips.search_company_transporter_name'
                                )
                              "
                              :init="false"
                              chips
                              required
                              multiple
                              v-bind="$attrs"
                              v-on="$listeners"
                            >
                              <template v-slot:prepend-item>
                                <v-list-item ripple @mousedown.prevent @click="toggle(a)">
                                  <v-list-item-action>
                                    <v-icon :class="icon" />
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{
                                        $t(
                                          "components.views.trip.no_offer_assign.modal_offer_trips.select_all"
                                        )
                                      }}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                              </template>
                              <template v-slot:selection="{ attrs, item, parent, selected }">
                                <v-chip v-bind="attrs" :input-value="selected" label small>
                                  <span class="pr-2">
                                    {{ item.businessName }}
                                  </span>
                                  <v-icon small @click="parent.selectItem(item)"> $delete </v-icon>
                                </v-chip>
                              </template>
                            </SelectAutocomplete>
                          </v-col>
                          <v-col cols="1" class="pt-0">
                            <v-tooltip bottom>
                              <template #activator="{ on, attrs }">
                                <v-btn
                                  color="red darken-2 white--text"
                                  class="my-1"
                                  depressed
                                  block
                                  style="height: 40px;"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="removeFields(a)"
                                  :disabled="fieldsFroms.length < 2"
                                >
                                  <v-icon size="16">fa-solid fa-minus</v-icon>
                                </v-btn>
                              </template>
                              <span>{{
                                $t(
                                  "components.views.trip.no_offer_assign.modal_offer_trips.delete_interval"
                                )
                              }}</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </div>
                      <v-row class="mt-4 card-modalbtn  pb-2" >
                        <v-col cols="12" >
                          <v-row class="justify-end pr-3">
                            <v-btn color="secondary" @click="hideAdvanced()" depressed class="ml-4" >
                              <v-icon small class="mr-1">fa-solid fa-arrow-left</v-icon>
                              {{
                                $t(
                                  "components.views.trip.no_offer_assign.modal_offer_trips.previous"
                                )
                              }}
                            </v-btn>
                            <v-btn color="primary" @click="offerAvanced()" :disabled="!validAdvance" depressed class="ml-4">
                              <v-icon small class="mr-1">fa-solid fa-check</v-icon>
                              {{
                                $t("components.views.trip.no_offer_assign.modal_offer_trips.offer")
                              }}
                            </v-btn>
                            <v-btn
                              color="grey lighten-1 white--text"
                              class="ml-4"
                              depressed
                              @click.stop="$emit('closeForm')"
                            >
                              <v-icon small size="15" class="mr-1"> fa-solid fa-xmark </v-icon>
                              {{
                                $t("components.views.trip.no_offer_assign.modal_offer_trips.cancel")
                              }}
                            </v-btn>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-stepper-content>
              </v-col>
            </v-row>
          </v-stepper-items>
        </v-stepper>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import validate from "@/plugins/validate";
export default {
  props: {
    idMonitoringOrder: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ...validate,
      stepType: "1",
      step: 1,
      minInit: new Date().toISOString(),
      fieldsFroms: [],
      validSimple: false,
      validAdvance: false,
      companys: 0,
      dataBusiness: [],
      advance: false,
      fieldFrom: {
        transportCompanies: [],
      },
    };
  },
  computed: {
    likesAllBusiness() {
      return this.companys.length === this.dataBusiness.length;
    },
    likesSomeBusiness() {
      return this.companys.length > 0 && !this.likesAllBusiness;
    },
    icon() {
      if (this.likesAllBusiness) return "fa-solid fa-square-check";
      if (this.likesSomeBusiness) return "fa-solid fa-square-minus";
      return "fa-solid fa-square";
    },
    nameSelect() {
      return this.stepType === "1"
        ? this.$t(
            "components.views.trip.no_offer_assign.modal_offer_trips.company_transporter_name"
          )
        : this.$t("components.views.trip.no_offer_assign.modal_offer_trips.driver");
    },
    lengthFieldsFroms() {
      return this.fieldsFroms.length;
    },
  },
  watch: {
    companys(newVal) {
      if (newVal.length == 0) {
        this.companys = 0;
      }
    },
  },
  mounted() {
    this.loadBusiness();
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    ...mapActions("loader", ["showLoader"]),

    onShowCogAdvance() {
      this.advance = true;
      this.step = 3;
      if (this.fieldsFroms.length == 0) {
        this.addFields();
      }
    },

    async offerAvanced() {

      this.showLoader({
        show: true,
      });

      let intervals = this.fieldsFroms.map((elementParams) => {
        let transportCompaniesBeforeMap = elementParams.transportCompanies;

        let transportCompanies = transportCompaniesBeforeMap.map((elementTransport) => {
          return {
            idCompany: elementTransport.idCompany,
            name: elementTransport.businessName,
          };
        });

        return {
          transportCompanies,
          valueStartTimeDate: this.moment(elementParams.valueStartTimeDate).utc().format("YYYY-MM-DD HH:mm"),
          valueEndTimeDate: this.moment(elementParams.valueEndTimeDate).utc().format("YYYY-MM-DD HH:mm"),
          valueTimePublic: elementParams.valueTimePublic,
        };
      });

      let params = {
        idMonitoringOrder: this.idMonitoringOrder,
        configAdvanced: true,
        intervals,
      };

      await this.axios
        .post("Offers/Publish", params)
        .then(() => {
          this.showSnack({
            text: this.$t(
              "components.views.trip.no_offer_assign.modal_offer_trips.interval_save_success"
            ),
            title: this.$t("components.views.trip.no_offer_assign.modal_offer_trips.success"),
            name: "success",
          });
        })
        .catch((error) => {
          this.showSnack({
            text: error.response.data,
            title: this.$t("components.views.trip.no_offer_assign.modal_offer_trips.save_error"),
            name: "error",
          });
        })
        .finally(() => {
          this.showLoader({
            show: false,
          });
          this.$emit("reloadPage");
        });
    },

    async offerSimple() {
      this.showLoader({
        show: true,
      });

      let transportCompanies = this.companys.map((element) => {
        return {
          name: element.businessName,
          idCompany: element.idCompany,
        };
      });

      let params = {
        idMonitoringOrder: this.idMonitoringOrder,
        configAdvanced: false,
        intervals: [
          {
            valueStartTimeDate: null,
            valueEndTimeDate: null,
            valueTimePublic: null,
            transportCompanies,
          },
        ],
      };

      await this.axios
        .post("/Offers/Publish", params)
        .then(() => {
          this.showSnack({
            text: this.$t("components.views.trip.no_offer_assign.modal_offer_trips.save_success"),
            title: this.$t("components.views.trip.no_offer_assign.modal_offer_trips.success"),
            name: "success",
          });
        })
        .catch((error) => {
          this.showSnack({
            text: error.response.data,
            title: this.$t("components.views.trip.no_offer_assign.modal_offer_trips.save_error"),
            name: "error",
          });
        })
        .finally(() => {
          this.showLoader({
            show: false,
          });
          this.$emit("reloadPage");
        });
    },
    toggle(index) {
      this.$nextTick(() => {
        this.companys = this.likesAllBusiness ? [] : this.dataBusiness.slice();
        let businessMap = this.companys.map((element) => {
          return {
            name: element.businessName,
            idCompany: element.idCompany,
          };
        });
        this.fieldsFroms[index].transportCompanies = [...businessMap];
      });
    },
    toggleSimple() {
      this.$nextTick(() => {
        this.companys = this.likesAllBusiness ? [] : this.dataBusiness.slice();
      });
    },
    addFields() {
      this.fieldsFroms.push({
        valueStartTimeDate: null,
        valueEndTimeDate: null,
        valueTimePublic: null,
        transportCompanies: [],
        propDisabled: true,
      });
    },
    removeFields(index) {
      this.fieldsFroms.splice(index, 1);
    },
    async loadBusiness() {
      await this.axios
        .get("/TransportCompany")
        .then((response) => {
          this.dataBusiness = response.data ? response.data : [];
        })
        .catch((err) => {
          console.log(err);
        });
    },
    publishTimeOn() {
      let fieldsFroms = this.fieldsFroms;

      fieldsFroms.map((item) => {
        if (item.valueEndTimeDate !== null) {
          let timeMinutes = this.moment(item.valueEndTimeDate).diff(
            this.moment(item.valueStartTimeDate),
            "seconds"
          );
          item.valueTimePublic = this.secondsToString(timeMinutes);
        }
        if (item.valueStartTimeDate !== null) {
          item.propDisabled = false;
        }
      });

      for (let i = 0; i < fieldsFroms.length; i++) {
        let InitAll =
          fieldsFroms[i].valueStartTimeDate !== null
            ? this.moment(fieldsFroms[i].valueStartTimeDate).format()
            : "";
        let EndAll =
          fieldsFroms[i].valueStartTimeDate !== null
            ? this.moment(fieldsFroms[i].valueEndTimeDate).format()
            : "";

        fieldsFroms.map((item, index) => {
          if (index != i) {
            let errorX = 0;
            let UpdateInit =
              item.valueStartTimeDate !== null ? this.moment(item.valueStartTimeDate).format() : "";
            let EndInit =
              item.valueEndTimeDate !== null ? this.moment(item.valueEndTimeDate).format() : "";

            if (EndInit !== "") {
              if (UpdateInit >= InitAll && EndInit <= EndAll) {
                errorX = 1;
              }

              if (UpdateInit >= InitAll && UpdateInit <= EndAll && EndInit >= EndAll) {
                errorX = 1;
              }

              if (errorX) {
                this.showSnack({
                  text: this.$t(
                    "components.views.trip.no_offer_assign.modal_offer_trips.error_interval_date"
                  ),
                  title: "Error",
                  name: "error",
                });
              }
            }
          }
        });
      }
    },
    hideAdvanced() {
      this.step = 2;
      this.advance = false;
    },
    secondsToString(seconds) {
      let hour = Math.floor(seconds / 3600);
      hour = hour < 10 ? "0" + hour : hour;
      let minute = Math.floor((seconds / 60) % 60);
      minute = minute < 10 ? "0" + minute : minute;
      return `${hour}Hr. ${minute}Min`;
    },
  },
};
</script>

<style scoped>
@import './ModalOfferTripsStyle.scss';
</style>
